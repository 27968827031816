// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,

      // apiUrl:'http://localhost:8080/ams' //local
        // apiUrl:'http://10.70.231.83:8080/ams' //Stagging
          apiUrl: 'https://lfadams.rajasthan.gov.in:8080/ams' // Production

    
};
